<template>
	<div class="footer d-flex flex-row align-items-center">
		<div class="label-footer" v-show="!toggleDetail">
			<div class="details">
				<div class="number">{{ user.numExpForUser }}</div>
				<div class="full-name">{{ user.fullName }}</div>
				<div class="text-truncate">{{ user.functionName }}</div>
			</div>
		</div>
		<div class="actions inline-flex justify-content-end" :style="`width: ${!toggleDetail ? '40%' : '100%'}`">
			<div class="cursor-pointer second-col w-100" @click="handleToggleDetail">
				<span class="mr-1">{{ !toggleDetail ? FormMSG(1, 'Show details') : FormMSG(2, 'Hide details') }}</span>
				<span v-if="!toggleDetail">
					<component :is="getLucideIcon(ICONS.CHEVRON_DOWN.name)" :size="18" />
				</span>
				<span v-else>
					<component :is="getLucideIcon(ICONS.CHEVRON_UP.name)" :size="18" />
				</span>
			</div>
		</div>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { isNil } from '~utils';

export default {
	name: 'ValidateExpenseFooter',
	mixins: [languageMessages, globalMixin],
	props: {
		user: {
			type: Object,
			required: true,
			default: () => {}
		},
		defaultToggleDetail: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			toggleDetail: false
		};
	},
	methods: {
		handleToggleDetail() {
			this.toggleDetail = !this.toggleDetail;
			this.$emit('validate-expense-footer:toggle', this.toggleDetail);
		}
	},
	watch: {
		defaultToggleDetail: {
			handler(value) {
				if (!isNil(value)) {
					this.toggleDetail = value;
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
<style lang="scss" scoped>
.label-footer {
	padding: 0 0 0 12px;
	width: 60%;
	color: rgba(6, 38, 62, 0.74);
	font-weight: 500;
	font-size: 0.85rem;
	white-space: nowrap;
	overflow-x: clip;
	text-overflow: ellipsis;
	.details {
		display: inline-flex;
		width: 100%;
	}
}

.full-name:after,
.number:after {
	content: '\A';
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #3c7442;
	display: inline-block;
	margin: 0 8px;
}
</style>
