var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.toggleDetail,
              expression: "toggleDetail",
            },
          ],
          staticClass: "card-inside-card",
        },
        [
          _c(
            "div",
            { staticClass: "card mb-0" },
            [
              _c("div", { staticClass: "wrapper" }, [
                _c("div", { staticClass: "header" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "first-col details d-flex align-items-center",
                    },
                    [
                      _c("div", { staticClass: "full-name" }, [
                        _vm._v(_vm._s(_vm.user.fullName)),
                      ]),
                      _c("div", [_vm._v(_vm._s(_vm.user.functionName))]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "second-col details d-flex justify-content-end",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "status validated px-2",
                          staticStyle: { width: "auto" },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.user.numExpForUser || _vm.user.numPoForUser
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("list", {
                attrs: {
                  items: _vm.items,
                  fields: _vm.fields,
                  "default-toggle-detail": _vm.toggleDetail,
                },
                on: {
                  "validate-expense-detail-list:validated": _vm.validate,
                  "validate-expense-detail-list:clicked": _vm.rowEdit,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("validate-expense-footer", {
        attrs: { "default-toggle-detail": _vm.toggleDetail, user: _vm.user },
        on: {
          "validate-expense-footer:toggle": _vm.toggleValidateExpenseDetail,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }