<template>
	<div>
		<div class="card-inside-card" v-show="toggleDetail">
			<div class="card mb-0">
				<!-- <pre>{{ user }}</pre> -->
				<div class="wrapper">
					<div class="header">
						<div class="first-col details d-flex align-items-center">
							<div class="full-name">{{ user.fullName }}</div>
							<div>{{ user.functionName }}</div>
						</div>
						<div class="second-col details d-flex justify-content-end">
							<div class="status validated px-2" style="width: auto">
								<span>{{ user.numExpForUser || user.numPoForUser }}</span>
							</div>
						</div>
					</div>
				</div>
				<list
					:items="items"
					:fields="fields"
					:default-toggle-detail="toggleDetail"
					@validate-expense-detail-list:validated="validate"
					@validate-expense-detail-list:clicked="rowEdit"
				/>
			</div>
		</div>
		<validate-expense-footer :default-toggle-detail="toggleDetail" :user="user" @validate-expense-footer:toggle="toggleValidateExpenseDetail" />
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import ValidateExpenseFooter from './ValidateExpenseFooter';
import List from './ValidateExpenseDetailList';
import { isNil } from '~utils';

export default {
	name: 'ValidateExpenseDetail',
	mixins: [languageMessages, globalMixin],
	components: { ValidateExpenseFooter, List },
	props: {
		user: {
			type: Object,
			required: true,
			default: null
		},
		items: {
			type: Array,
			required: true,
			default: () => []
		},
		defaultToggleDetail: {
			type: [Boolean, String],
			required: false,
			default: false
		},
		fields: {
			type: Array,
			required: true,
			default: () => []
		}
	},
	data() {
		return {
			toggleDetail: false
		};
	},
	methods: {
		toggleValidateExpenseDetail(payload) {
			this.toggleDetail = !!payload;
		},
		validate(payload) {
			if (payload) {
				this.$emit('validate-expense-detail:validated', payload);
			}
		},
		rowEdit(payload) {
			if (payload) {
				this.$emit('validate-expense-detail:clicked', payload);
			}
		}
	},
	watch: {
		defaultToggleDetail: {
			handler(value) {
				if (!isNil(value)) {
					this.toggleDetail = value === 'true' ? true : false;
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
<style lang="scss" scoped>
.container-mobile .card .wrapper .header .first-col {
	&.details {
		width: 90%;
		font-weight: 600;
		font-size: 0.95rem;
		color: rgba(6, 38, 62, 0.74);
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
.container-mobile .card .wrapper .header .second-col {
	&.details {
		width: 10%;
	}
}
.full-name:after {
	content: '\A';
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #3c7442;
	display: inline-block;
	margin: 0 8px;
}
</style>
