<template>
	<div>
		<div class="card-inside-card" v-if="toggleTsDetail">
			<card-list-builder
				:use-new-version="true"
				:items="items"
				:fields="defaultFields"
				:has-badge="true"
				:is-badge-use-props="true"
				badge-class="statusClass"
				:hide-status="false"
				badge-value="validatedStatus"
				:toggle-mode="true"
			>
				<template slot="encodedBy" slot-scope="{ item }"> {{ item.encoder.name }} {{ item.encoder.firstName }} </template>
				<template slot="val" slot-scope="{ item }">
					<b-button size="sm" class="btn bg-transparent border-0 pr-0" @click="validateItem(item.id)">
						<component :is="getLucideIcon(ICONS.CHECK_SQUARE.name)" :color="ICONS.CHECK_SQUARE.color" :size="20" />
					</b-button>
				</template>
				<template slot="actions" slot-scope="{ item }">
					<b-button size="sm" class="btn bg-transparent border-0 ml-1" @click="rowEdit(item)">
						<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
					</b-button>
				</template>
			</card-list-builder>
		</div>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { isNil } from '~utils';

export default {
	name: 'ValidateExpenseDetailList',
	mixins: [languageMessages, globalMixin],
	props: {
		items: {
			type: Array,
			required: true,
			default: () => []
		},
		defaultToggleDetail: {
			type: [Boolean, String],
			required: false,
			default: false
		},
		fields: {
			type: Array,
			required: true,
			default: () => []
		}
	},
	data() {
		return {
			toggleTsDetail: false,
			defaultFields: []
		};
	},
	methods: {
		toggleTimeSheetDetail(payload) {
			this.toggleTsDetail = !!payload;
		},
		async validateItem(id) {
			this.$bvModal
				.msgBoxConfirm(this.FormMSG(11, 'Are you sure to validate this?'), {
					title: this.FormMSG(12, 'Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.FormMSG(13, 'YES'),
					cancelTitle: this.FormMSG(14, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false,
					noCloseOnBackdrop: true
				})
				.then(async (v) => {
					if (!v) return;
					this.$emit('validate-expense-detail-list:validated', id);
				})
				.catch((err) => console.log(err));
		},
		rowEdit(payload) {
			this.$emit('validate-expense-detail-list:clicked', payload);
		}
	},
	watch: {
		defaultToggleDetail: {
			handler(value) {
				if (!isNil(value)) {
					this.toggleTsDetail = value;
				}
			},
			deep: true,
			immediate: true
		},
		fields: {
			handler(value) {
				if (!isNil(value)) {
					const keys = ['validatedStatus'];
					this.defaultFields = value;
					this.defaultFields = this.defaultFields.filter((t) => t && !keys.includes(t.key));
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
